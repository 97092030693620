<template>
      <div class='classExample flex-column-between' @click="iconCut=false">
            <entrance :sitedata="true" @changTip="changTip"></entrance>
            <div class="iput flex-align-center">
                  <div class="iputTitle">
                        <i class="el-icon-arrow-down"></i>
                        <span>{{className}}</span>
                        <i class="iclass" :class="iconCut?'el-icon-arrow-up':'el-icon-arrow-down'"
                              @click.stop="iconCut = !iconCut"></i>
                  </div>
                  <transition name="normal-expand">
                        <div class="classList" v-if="iconCut">
                              <ul class="ulBox">
                                    <li :class="{'libg':classIndex == index}" v-for="(item,index) in classArr"
                                          :key="index" @click.stop="pitchOn(index,item)">{{item.name}}</li>
                              </ul>
                        </div>
                  </transition>
                  <!-- <vxe-select v-model="value10" placeholder="默认尺寸" @change="classChange">
                        <vxe-option v-for="(item,index) in classArr" :key="index" :value="item.name" :label="item.name">
                        </vxe-option>
                  </vxe-select> -->
            </div>
            <div class="main">
                  <el-timeline>
                        <el-timeline-item v-for="(item, index) in Lessons" :key="index" :timestamp="item.courseName"
                              placement="top">
                              <div class="courseware">
                                    <ul>
                                          <li class="flex-align"
                                                :class="index === signIndex && indexs === signIndexSon && signIndexSon !=null && signIndex !=null?'libg2':'libg1'"
                                                v-for="(itemson,indexs) in item.data" :key="indexs"
                                                @click.stop="sign(index,indexs,itemson)">
                                                <img :src="itemson.icon2"
                                                      v-if="index === signIndex&& indexs === signIndexSon && signIndexSon !=null && signIndex !=null">
                                                <img :src="itemson.icon1" v-else>
                                                <span>{{itemson.typeName}}</span>
                                          </li>
                                    </ul>
                              </div>
                        </el-timeline-item>
                  </el-timeline>

            </div>
            <div class="base"></div>
            <div class="dialogBox">
                  <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="65%" center top="8vh"
                        @close='closeDialog'>
                        <div class="iframeDom" v-if="dialogShow && refreshshow">
                              <pdf v-for="i in numPages" :key="i" :src="pdfUrl" :page="i"></pdf>
                        </div>
                        <div class="playerBox" v-else>
                              <div id="player" ref="audioVoice"></div>
                        </div>
                        <div class="spanstyle flex-align-center">
                              <span @click.stop="centerDialogVisible =false">
                                    关闭
                              </span>
                        </div>
                  </el-dialog>
            </div>
            <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
      </div>
</template>

<script>
      import Vue from 'vue';
      import entrance from '../../Common_components/head/entrance';

      import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
      import pdf from "vue-pdf";
      import subscribe from "../../Common_components/Popup/subscribe.vue";
      import {
            mapState,
            mapMutations
      } from "vuex";
      export default {
            data() {
                  return {
                        signIndex: '',
                        signIndexSon: '',
                        classArr: [],
                        value10: '请选择',
                        gradeId: '',
                        Lessons: '',
                        centerDialogVisible: false,
                        numPages: null,
                        pdfUrl: "",
                        dialogTitle: '',
                        player: '',
                        dialogShow: '',
                        refreshshow: false,
                        iconCut: false,
                        classIndex: 0,
                        className: '请选择班级',
                        centerDialogVisible2: false

                  }
            },
            components: {
                  entrance,
                  pdf,
                  subscribe
            },
            //方法
            methods: {
                  ...mapMutations(["hint_data"]),
                  changTip(value) {
                        if (value) {
                              this.hint_data("暂无活动");
                              this.centerDialogVisible2 = true;
                        }

                  },
                  clear(bool) {
                        this.centerDialogVisible2 = bool;

                  },
                  sign(index, indexs, value) {
                        // console.log('value', value)
                        this.signIndex = index;
                        this.signIndexSon = indexs;
                        // console.log('index,indexs', index, indexs);
                        this.centerDialogVisible = true;

                        if (value.type == "MP4") {
                              // this.player.destroy();
                              this.player = '';
                              this.loadPlayer(value.value);
                              this.dialogShow = false;
                              this.dialogTitle = value.typeName;
                        } else {
                              this.refreshshow = true;
                              // console.log('--1---', value.typeName)
                              // console.log('--2---', this.dialogTitle)
                              // if (this.dialogTitle == value.typeName) return false;
                              this.dialogTitle = value.typeName;
                              this.getNumPages(value.value);

                              this.numPages = null;
                              this.pdfUrl = "";
                              this.dialogShow = true
                        }
                  },
                  closeDialog() {
                        this.refreshshow = false;
                        if (this.dialogShow) return false;
                        this.player.destroy();
                  },
                  async getclass() {
                        this.$Api.Home.getCourseInfo().then(res => {
                              if (res.code == 500) return false;
                              const resData = new Map();
                              let arr = res.data;
                              let dataArr = [];
                              dataArr = arr.filter((arr) => !resData.has(arr.termId) && resData
                                    .set(arr.termId,
                                          1));;
                              for (let i = 0; i < dataArr.length; i++) {
                                    let index = dataArr[i].name.split('级')[0];
                                    this.classArr.push({
                                          name: index + '级',
                                          grade: dataArr[i].termId
                                    })
                              }
                              this.className = this.classArr[0].name;
                              // this.value10 = this.classArr[0].name;
                              this.gradeId = this.classArr[0].grade;
                              this.getExample()
                              // console.log('this.classArr', this.classArr)
                        })
                  },
                  async getExample() {
                        let changeId = this.gradeId != 1 ? (this.gradeId % 2 == 0 ? this.gradeId - 1 : this
                                    .gradeId) : this
                              .gradeId
                        let resData = await this.$Api.Example.getExample(changeId);
                        console.log('data', resData.data)
                        let data = resData.data;
                        for (let i = 0; i < data.length; i++) {
                              this.$set(data[i], 'data', [{
                                          'value': data[i].teachPlan,
                                          'typeName': data[i].teachPlanName,
                                          'sign': data[i].gradeId + 'P',
                                          'type': 'pdf',
                                          'icon1': require('../../assets/classExample/pdfA.png'),
                                          'icon2': require('../../assets/classExample/pdfB.png')
                                    },
                                    {
                                          'value': data[i].exampleVideo,
                                          'typeName': data[i].exampleVideoName,
                                          'sign': data[i].gradeId + 's',
                                          'type': 'MP4',
                                          'icon1': require('../../assets/classExample/mp4A.png'),
                                          'icon2': require('../../assets/classExample/mp4B.png')
                                    }
                              ])

                        }
                        this.Lessons = data;
                        // console.log('this.Lessons', this.Lessons)

                  },
                  // classChange(value) {
                  //       console.log(value, value)
                  //       this.gradeId = this.classArr[this.IndexEvent(value, this.classArr)].grade;
                  //       // console.log('this.gradeId', this.gradeId)
                  //       this.getExample()
                  // },
                  // IndexEvent(value, data) {

                  //       let index = '';
                  //       return index = data.findIndex(item => {
                  //             return value.value === item.name;
                  //       });
                  // },
                  getNumPages(value) {
                        // let datas = '';
                        this.numPages = null;
                        this.pdfUrl = "";
                        let loadingTask = pdf.createLoadingTask(value);
                        loadingTask.promise.then(pdf => {
                              this.pdfUrl = loadingTask;
                              this.numPages = pdf.numPages;
                        }).catch(err => {
                              console.error('pdf 加载失败', err);
                        })
                  },
                  async loadPlayer(value) {
                        let params = {
                              vid: value
                        };
                        let that = this;
                        this.$Api.Home.getVid(params).then(res => {
                              let data = res.data.playsafe;
                              this.player = new polyvPlayer({
                                    wrap: '#player',
                                    vid: value,
                                    'loop': false,
                                    'autoplay': false,
                                    'playsafe': data,
                                    'hideSwitchPlayer': true
                              });
                              //播放
                              that.player.on('s2j_onVideoPlay', that.attplay);
                              //暂停
                              that.player.on('s2j_onVideoPause', that.attpause);



                        }).catch(err => {

                        })
                  },
                  pitchOn(index, value) {
                        this.classIndex = index;
                        this.className = value.name;
                        this.gradeId = value.grade;
                        this.getExample();
                        this.iconCut = false;
                  }

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.getclass();
                  // this.getNumPages();

            }
      }
</script>
<style lang='less' scoped>
      @import './less/classExample';
</style>